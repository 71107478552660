import { graphql, Link } from "gatsby";
import React from "react";
import FromTheBlogList from "../components/from-the-blog-list";
import Layout from "../components/layout";
import Seo from "../components/seo"
import sanitize from "../helpers/html-sanitizer";
import BlogSidebar from "../components/blog-sidebar";
import { extractCaption, getAuthorUrl } from "../helpers/blog-helpers";

const wpUrl = process.env.GATSBY_WORDPRESS_INSTANCE;

export default function BlogPost({ data }) {
  const post = data.wpPost;
  const feedPosts = data?.allWpPost?.nodes || [];
  const authorName = post?.author?.node?.name;
  const authorSlug = post?.author?.node?.slug;
  const comments = post?.commentCount || 0;
  const featuredImage = post?.featuredImage?.node?.localFile?.publicURL;
  const caption = extractCaption(post);

  return (
    <Layout>
      <Seo
        title={post.title}
      />
      <section>
        <div className="container my-6">
          <div className="row">
            <div className="col-xl-9">
              <div className="row">
                <div className="col-12">
                  <h1 className="mb-5">{post.title}</h1>
                  <p className="post-meta mb-4">
                    By <Link to={getAuthorUrl(authorSlug)}>{authorName}</Link> | <b>{post.date}</b> | {comments} Comments
                  </p>
                </div>
                <div className="col-12 mb-5">
                  <img
                    className="img-fluid"
                    src={featuredImage}
                    alt={caption}
                  />
                </div>
                <div className="col-12">
-                  <article className="blog-article"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(post.content, wpUrl),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </section>
      <FromTheBlogList posts={feedPosts} />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $categorySlug: String = "//") {
    wpPost(slug: { eq: $slug }) {
      title
      commentCount
      author {
        node {
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
          caption
        }
      }
      content
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          slug
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 3
      filter: {
        categories: { nodes: { elemMatch: { slug: { regex: $categorySlug } } } }
        slug: { ne: $slug }
      }
    ) {
      nodes {
        title
        excerpt
        date(formatString: "MMM D, YYYY")
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
            caption
          }
        }
        author { node { name slug } }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }
`;